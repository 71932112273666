import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found | 404</title>
        <meta
          name="description"
          content="Baseline is an efficient and cost effective resource planning tool that builds and manages the optimal teams for your projects."
        />
        <meta
          name="keywords"
          content="Saas, Cheap, Time Saving ,Team, Team Management,Mental Health,Best Team Management Tool, Best Project Management Tool,Easy to use Team Management Tool,Easy to use Project Management Tool,Monitoring profitability,Ways to improve profitability,Time management,How to improve mental health,How to build a successful team,Staffing tool,Utilization tool,HR tool or HR Software,Resource allocation,People first team creation,Hybrid workforce,Visualize department utilization,Collaborative team building,Hard and Soft skill data talent search,User friendly team management,Future workforce,Creating happy and profitable teams,How can I see burnout in my team,"
        />
        <link rel="canonical" href="https://baselineteams.com" />
      </Helmet>

      <main style={pageStyles}>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <p style={paragraphStyles}>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code style={codeStyles}>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </main>
    </>
  );
};

export default NotFoundPage;
